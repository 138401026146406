import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
  path: "/",
  name: "RootHome",
  component: () =>
    import("./views/Home.vue")
},
{
  path: '/app',
  name: 'AppRoot',
  redirect: '/app/dashboard',
  component: () => import('./views/AppRoot.vue'),
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: () => import('./views/Dashboard.vue')
  },
  {
    path: 'users',
    name: 'Users',
    component: () => import('./views/Users.vue')
  },
  {
    path: 'eventlog',
    name: 'Eventlog',
    component: () => import('./views/Eventlog.vue')
  },
  {
    path: 'logstream',
    name: 'Logstream',
    component: () => import('./views/Logstream.vue')
  },
  {
    path: 'deviceconfigs',
    name: 'Deviceconfigs',
    component: () => import('./views/Deviceconfigs.vue')
  },
  {
    path: 'temperatures',
    name: 'Temperatures',
    component: () => import('./views/Temperatures.vue')
  },
  {
    path: 'deviceerrors',
    name: 'Deviceerrors',
    component: () => import('./views/Deviceerrors.vue')
  },
  {
    path: 'cameras',
    name: 'Cameras',
    component: () => import('./views/Cameras.vue')
  },
  {
    path: 'playoutscreen',
    name: 'Playoutscreen',
    component: () => import('./views/Playoutscreen.vue')
  },
  {
    path: 'playouttrain',
    name: 'Playouttrain',
    component: () => import('./views/Playouttrain.vue')
  },
  {
    path: 'stationtap',
    name: 'Stationtap',
    component: () => import('./views/Stationtap.vue')
  }
  ]
},
{
  path: '/**',
  name: 'Error404',
  component: () => import('./views/Error404.vue'),
}
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  // console.log("to:" + to.path)
  // console.log("from:" + from.path)
  if (to.path.startsWith("/app")) {
    let AUTH_HEADER = localStorage.getItem("AUTH_HEADER")
    if (AUTH_HEADER !== null) {
      next()
    } else {
      localStorage.removeItem("AUTH_HEADER")
      localStorage.removeItem("USER_OBJECT")
      next(false)
    }
  } else {
    next()
  }
});


export default router;