import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    USER_OBJECT: {},
    AUTH_HEADER: "",
  },
  getters: {
    USER_OBJECT: state => {
      return state.USER_OBJECT
    },
    AUTH_HEADER: state => {
      return state.AUTH_HEADER
    }
  },
  mutations: {
    UPDATE_USER_OBJECT(state, USER_OBJECT) {
      state.USER_OBJECT = USER_OBJECT
    },
    UPDATE_AUTH_HEADER(state, AUTH_HEADER) {
      state.AUTH_HEADER = AUTH_HEADER
    }
  },
  actions: {
    POST_LOGIN: (_, payload) => {
      return new Promise((resolve, reject) => {
        delete axios.defaults.headers.common['Authorization']
        axios({
          url: '/auth/sign_in',
          data: payload,
          method: 'POST'
        })
          .then(response => {
            axios.defaults.headers.common['Authorization'] = response.data.signature
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    GET_USERS: () => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/users',
          method: 'GET'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    POST_ADD_USER: (_, payload) => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/user/add',
          data: payload,
          method: 'POST'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    POST_DELETE_USER: (_, payload) => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/user/delete/' + payload.id,
          data: null,
          method: 'POST'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    POST_UPDATE_USER: (_, payload) => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/user/update/' + payload.id,
          data: payload,
          method: 'POST'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    GET_AUTH_LOGS: () => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/logging/get_auth_logs',
          method: 'GET'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    GET_SYSTEM_LOGS: () => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/logging/get_system_logs',
          method: 'GET'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    GET_DEVICE_LOGS: () => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/logging/get_device_logs',
          method: 'GET'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    GET_DASHBOARD_DEVICES: () => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/dashboard/devices',
          method: 'GET'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    GET_DASHBOARD_MEDIACOUNTER: () => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/dashboard/mediacounter',
          method: 'GET'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    GET_DASHBOARD_DEVICE_INFOSTORE: () => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/dashboard/device_infostore',
          method: 'GET'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    GET_DEVICE_CONFIGS: () => {
      console.log(axios.defaults.headers.common)
      return new Promise((resolve, reject) => {
        axios({
          url: '/device/configs',
          method: 'GET'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    POST_UPDATE_DEVICE_CONFIG: (_, payload) => {
      let {
        editDevice
      } = payload
      return new Promise((resolve, reject) => {
        axios({
          url: '/device/update_device_config',
          data: {
            ...editDevice
          },
          method: 'POST'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    POST_COMMIT_DEVICE_CONFIG: (_, payload) => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/device/commit_device_config/' + payload.id,
          data: null,
          method: 'POST'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    POST_DECOMMISSION_DEVICE: (_, payload) => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/device/decommission_device/' + payload.id,
          data: null,
          method: 'POST'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    POST_DELETE_DEVICE: (_, payload) => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/device/delete_device/' + payload.id,
          data: null,
          method: 'POST'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    POST_REPLACE_DEVICE: (_, payload) => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/device/replace_device/' + payload.id + '/' + payload.sn,
          data: null,
          method: 'POST'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    GET_ALL_TEMPERATURES: () => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/temperature/get_all',
          method: 'GET'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    GET_ALL_CAMS: () => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/cameras/get_all',
          method: 'GET'
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    GET_PLAYOUT_STATION: (_, payload) => {
      return new Promise((resolve, reject) => {
        let spotid = payload.spotid
        axios({
          url: '/playout/get_station/' + spotid,
          method: 'GET'
        }, {
          timeout: 60 * 1000
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    GET_PLAYOUT_MOBILE: (_, payload) => {
      return new Promise((resolve, reject) => {
        let spotid = payload.spotid
        axios({
          url: '/playout/get_mobile/' + spotid,
          method: 'GET'

        }, {
          timeout: 60 * 1000
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
  modules: {}
});