import Vue from "vue";
import App from "./App.vue";
import "./plugins/axios";
import router from "./router.js";
import store from "./store.js";
import VModal from 'vue-js-modal';

Vue.use(VModal)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
